import axios from "axios";

const USERNAME_URL = process.env.REACT_APP_USERNAME_URL;
const GUILD_NAME = process.env.REACT_APP_GUILD_NAME;
const API_KEY = process.env.REACT_APP_API_KEY;

function getGuildHexColor(tagColor) {
    switch (tagColor) {
        case "GOLD":
            return "#FFD700";
        case "YELLOW":
            return "#FFFF00";
        case "DARK_GREEN":
            return "#006400";
        case "DARK_AQUA":
            return "#008B8B";
        default:
            return "#AAAAAA";
    }
}

function dateBuilder(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

const uuidToName = async (uuid) => {
    try {
        const url = USERNAME_URL + "/username/" + uuid;
        const response = await axios.get(url, {
            headers: {
                "x-api-key": API_KEY,
            },
        });

        if (!response.data.username) {
            throw new Error(response.data.error);
        }

        return response.data.username;
    } catch (error) {
        return error;
    }
};

const getRankData = async (uuid) => {
    try {
        const response = await fetch(`${USERNAME_URL}/rank/${uuid}`, {
            headers: {
                "x-api-key": API_KEY,
            },
        });

        const data = await response.json();
        if (!data.rank) {
            console.log(data);
            throw new Error(data.error);
        }

        const { rank, plusColor, rankColor } = data;

        const plusIndex = rank.indexOf("+"); // Index of the plus sign in the rank
        const rankPart = plusIndex === -1 ? rank : rank.slice(0, plusIndex); // Rank without the plus sign
        const plusPart = plusIndex === -1 ? "" : rank.slice(plusIndex); // Plus sign

        return {
            rank: rankPart,
            rankColor: rankColor,
            plus: plusPart,
            plusColor: plusColor,
        };
    } catch (error) {
        return error;
    }
};

function sortMembers(e, ArrayToSort, setFinalMembers, sortedRank) {
    const column = e.target; // Column to sort by
    let sortOrder = 1; // Order of sorting
    let sortBy = e.target.dataset.sort; // Data attribute to sort by
    let sorted = []; // Array to store sorted members

    // Syncs the sorting order with the column header's class
    if (column.classList.contains("asc")) {
        sortOrder = -1;
        column.classList.remove("asc");
        column.classList.add("desc");
    } else {
        sortOrder = 1;
        column.classList.remove("desc");
        column.classList.add("asc");
    }

    // Sorts the members based on the column clicked
    // if Rank
    if (sortBy === "Rank") {
        sorted = [...ArrayToSort].sort((a, b) => {
            return (
                sortOrder *
                (sortedRank.indexOf(a.guildRank) -
                    sortedRank.indexOf(b.guildRank))
            );
        });

        // If join date
    } else if (sortBy === "Joined") {
        sorted = [...ArrayToSort].sort((a, b) => {
            return sortOrder * (new Date(a.joined) - new Date(b.joined));
        });

        // If weekly XP
    } else if (sortBy === "Weekly XP") {
        sorted = [...ArrayToSort].sort((a, b) => {
            return sortOrder * (a.WeeklyXP - b.WeeklyXP);
        });
    }

    // Sets the sorted members to the state
    setFinalMembers(sorted);
}

async function getGuildData() {
    try {
        const response = await fetch(`${USERNAME_URL}/guild/${GUILD_NAME}`, {
            headers: {
                "x-api-key": API_KEY,
            },
        });
        const data = await response.json();

        if (data?.error) {
            throw new Error(data?.error || "An error occurred");
        }

        return data;
    } catch (error) {
        throw new Error(error.message || "An error occurred");
    }
}

export {
    getGuildHexColor,
    dateBuilder,
    uuidToName,
    getRankData,
    sortMembers,
    getGuildData,
};
