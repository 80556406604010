import { useEffect, useMemo, useState } from "react";
import {
    getGuildHexColor,
    dateBuilder,
    uuidToName,
    getRankData,
    sortMembers,
    getGuildData,
} from "./functions";
import "./App.css";

const App = () => {
    // State variables
    const [guildData, setGuildData] = useState(null); // to store guild data from 1st API call
    const [guildMembers, setGuildMembers] = useState([]); // to store guild members from 1st API call (sorted by rank)
    const [finalMembers, setFinalMembers] = useState([]); // to store guild members from 2nd API call (with name, and rank data)
    const [error, setError] = useState(null); // to store error messages if any occur

    const sortedRank = useMemo(
        () => ["Guild Master", "Co-Owner", "Officer", "Elites", "Member"],
        []
    ); // Array of ranks sorted by priority

    // UseEffect to fetch guild data from Hypixel API
    useEffect(() => {
        async function fetchData() {
            try {
                const guildData = await getGuildData();
                setGuildData(guildData);
                setGuildMembers(
                    guildData.members.sort(
                        (a, b) =>
                            sortedRank.indexOf(a.rank) -
                            sortedRank.indexOf(b.rank)
                    )
                );
            } catch (error) {
                setError(error);
                return;
            }
        }

        fetchData();
    }, [sortedRank]);

    // UseEffect to convert UUID to username and add to finalMembers
    useEffect(() => {
        if (guildMembers) {
            if (error) return;
            if (!error) {
                // guildMembers.map((member, index) =>
                //     setTimeout(async () => {
                //         try {
                //             // Converts UUID to username & gets rank data from Hypixel API in parallel
                //             const [username, rankData] = await Promise.all([
                //                 uuidToName(member.uuid),
                //                 getRankData(member.uuid),
                //             ]);

                //             // If the rank data is undefined, it means the API limit has been reached
                //             if (!rankData.rank) {
                //                 setError({
                //                     error: "API LIMIT REACHED",
                //                     message: "Please try again later",
                //                 });
                //                 throw new Error("API LIMIT REACHED");
                //             }

                //             // Destructures the rank data
                //             const { rank, rankColor, plus, plusColor } =
                //                 rankData;
                //             // Calculates the weekly XP
                //             const weeklyXP = Object.values(
                //                 member.expHistory
                //             ).reduce((a, b) => a + b);

                //             // Push the current member to the finalMembers state
                //             setFinalMembers((finalMembers) => [
                //                 ...finalMembers,
                //                 {
                //                     id: member.uuid,
                //                     username: username,
                //                     hypixelRank: {
                //                         rank: rank,
                //                         rankColor: rankColor,
                //                         plus: plus,
                //                         plusColor: plusColor,
                //                     },
                //                     guildRank: member.rank,
                //                     joined: member.joined,
                //                     WeeklyXP: weeklyXP,
                //                 },
                //             ]);
                //         } catch (error) {
                //             setError(error);
                //             return;
                //         }
                //     }, index * 400)
                // );

                for (let i = 0; i < guildMembers.length; i++) {
                    try {
                        const getFinalMembers = async () => {
                            // Converts UUID to username & gets rank data from Hypixel API in parallel
                            const [username, rankData] = await Promise.all([
                                uuidToName(guildMembers[i].uuid),
                                getRankData(guildMembers[i].uuid),
                            ]);

                            // If the rank data is undefined, it means the API limit has been reached
                            if (!rankData.rank) {
                                setError({
                                    error: "API LIMIT REACHED",
                                    message: "Please try again later",
                                });
                                throw new Error("API LIMIT REACHED");
                            }

                            // Destructures the rank data
                            const { rank, rankColor, plus, plusColor } =
                                rankData;
                            // Calculates the weekly XP
                            const weeklyXP = Object.values(
                                guildMembers[i].expHistory
                            ).reduce((a, b) => a + b);

                            // Push the current member to the finalMembers state
                            setFinalMembers((finalMembers) => [
                                ...finalMembers,
                                {
                                    id: guildMembers[i].uuid,
                                    username: username,
                                    hypixelRank: {
                                        rank: rank,
                                        rankColor: rankColor,
                                        plus: plus,
                                        plusColor: plusColor,
                                    },
                                    guildRank: guildMembers[i].rank,
                                    joined: guildMembers[i].joined,
                                    WeeklyXP: weeklyXP,
                                },
                            ]);
                        };

                        getFinalMembers();
                    } catch (error) {
                        setError(error);
                        return;
                    }
                }
            }
        }
    }, [guildMembers, error]);

    if (error) {
        return (
            <div className="error">
                <h1>
                    {error.error ? (
                        <h1>{error.error}</h1>
                    ) : (
                        "Something went wrong"
                    )}
                </h1>
                <p>
                    {error.message ? error.message : "Please try again later"}
                </p>
            </div>
        );
    }

    return finalMembers.length > 0 ? (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>IGN</th>
                        <th
                            data-sort="Rank"
                            onClick={(e) =>
                                sortMembers(
                                    e,
                                    finalMembers,
                                    setFinalMembers,
                                    sortedRank
                                )
                            }
                        >
                            Rank{" "}
                        </th>
                        <th
                            data-sort="Joined"
                            onClick={(e) =>
                                sortMembers(
                                    e,
                                    finalMembers,
                                    setFinalMembers,
                                    sortedRank
                                )
                            }
                        >
                            Joined
                        </th>
                        <th
                            data-sort="Weekly XP"
                            onClick={(e) =>
                                sortMembers(
                                    e,
                                    finalMembers,
                                    setFinalMembers,
                                    sortedRank
                                )
                            }
                        >
                            Weekly XP
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {finalMembers.map((member) => {
                        const { username, joined, guildRank, id, WeeklyXP } =
                            member;
                        const { rank, rankColor, plus, plusColor } =
                            member.hypixelRank;

                        const guildTagColor = getGuildHexColor(
                            guildData.tagColor
                        );

                        let memberRankTag;
                        if (guildRank === "Guild Master") {
                            memberRankTag = "[GM]";
                        } else {
                            try {
                                memberRankTag = guildData.ranks.find(
                                    (rank) => rank.name === guildRank
                                ).tag;
                                memberRankTag !== null &&
                                    (memberRankTag = `[${memberRankTag}]`);
                            } catch (error) {
                                memberRankTag = null;
                            }
                        }

                        const player_name =
                            rank === "NON" ? (
                                <div
                                    className="player_name"
                                    style={{
                                        padding: "1em",
                                        color: rankColor,
                                    }}
                                >
                                    {username}
                                </div>
                            ) : (
                                <div
                                    className="player_name"
                                    style={{
                                        padding: "16px 2px",
                                        color: rankColor,
                                    }}
                                >
                                    [{rank}
                                    <span
                                        style={{
                                            color: plusColor,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {plus}
                                    </span>
                                    ] {username}
                                </div>
                            );

                        return (
                            <tr key={id}>
                                <td className="player">
                                    <img
                                        className="player_avatar"
                                        src={`https://crafatar.com/avatars/${id}?overlay=true`}
                                        alt="Player Avatar"
                                    />
                                    <>
                                        {player_name}
                                        <div
                                            className="player_guild"
                                            style={{
                                                padding: "16px 2px",
                                                color: guildTagColor,
                                            }}
                                        >
                                            {memberRankTag}
                                        </div>
                                    </>
                                    {}
                                </td>
                                <td>{guildRank}</td>
                                <td>{dateBuilder(new Date(joined))}</td>
                                <td>
                                    {WeeklyXP.toString().replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                    )}{" "}
                                    XP
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    ) : (
        <div className="loading">
            <h1>Loading...</h1>
        </div>
    );
};

export default App;
